@use "../../App.scss" as *;

.our-products-container {
  width: 1150px;
  margin: auto;
  @include flex-centre();
  flex-direction: column;

  .icons-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    padding: 40px 0;
    gap: 40px;

    div {
      @include flex-centre();
      flex-direction: column;
      justify-content: flex-start;
      .icon {
        width: 100px;
      }
    }
  }

  .text-container {
    width: 700px;
    margin-bottom: 80px;
  }

  .card-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
    margin-bottom: 80px;

    .product-card {
      border: 1px solid #b3b3b3;
      padding: 20px;
      position: relative;
      transition: all ease 0.2s;
      overflow: hidden;

      img {
        max-width: 80%;
      }

      
      .button-container-product-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        justify-content: center;
        transform: translateY(100px);
        transition: all ease 0.2s;
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 10px;
        
       // .product-btn {
          // transform: translateY(100px);
          // transition: all ease 0.2s;
          // position: absolute;
          // bottom: 10px;
          // left: 10px;
          // right: 10px;
          
      //  }
      }
      

      &:hover .button-container-product-card {
        transform: translateY(0);
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .our-products-container {
    width: 90%;
    padding: 40px 0;
    @include flex-centre();
    flex-direction: column;

    .icons-grid {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      padding: 40px 0;
      gap: 40px;

      div {
        @include flex-centre();
        flex-direction: column;
        justify-content: flex-start;
        .icon {
          width: 100px;
        }
      }
    }

    .text-container {
      width: 700px;
      margin-bottom: 80px;
    }

    .card-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 20px;
      margin-bottom: 80px;

      .product-card {
        border: 1px solid #b3b3b3;
        padding: 20px;
        position: relative;
        transition: all ease 0.2s;
        overflow: hidden;

        &:hover .product-btn {
          transform: translateY(0);
        }

        .product-btn {
          transform: translateY(100px);
          transition: all ease 0.2s;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 775px) {
  .our-products-container {
    .text-container {
      width: 90%;
      margin-bottom: 20px;
    }

    .card-container {
      grid-template-columns: 1fr;
    }

    .icons-grid {
      grid-template-columns: 1fr 1fr;

    }
  }

  @media screen and (max-width: 400px) {
    .our-products-container {
      .text-container {
        width: 95%;
      }
  
      .card-container {
        grid-template-columns: 1fr;
      }
  
      .icons-grid {
        grid-template-columns: 1fr;
  
      }
    }
  }
}
// .product-card:hover .hover-info {
//   scale: 1;
// }

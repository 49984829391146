.container-form-contianer {
  width: 100%;
  // display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .inner-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    //background-color: aqua;

    .form {
      width: 100%;
      height: 100%;
      border: 1px solid black;
      background-color: rgba(255, 255, 255, 0.75);
      margin: auto;

      form {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 40px;

        label {
          color: black;
          font-size: 16pt;
        }

        input {
          width: 100%;
          border: none;
          border-bottom: 1px solid black;
          padding: 5px;
          background-color: transparent;
          margin-bottom: 20px;
          font-size: 16pt;
          color: black;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .container-form-contianer {
    width: 100%;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 40px;

    .inner-container {
      width: 90%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;

      // 
    }
  }
}

@media screen and (max-width: 400px) {
    .container-form-contianer {
      width: 100%;
      background-size: cover;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  
      .inner-container {
        width: 95%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }
    }
  }
  
.contact-header {
  color: white;
  margin-bottom: 20px;
}

@use "../../App.scss" as *;

.spacer-img {
    width: 100%; 
    height: 500px;
    margin: 40px 0;
    background-image: url("../../assets/images/spacer.jpg");
    background-position: 50% 75%;
    background-size: cover;
    @include flex-centre();
    flex-direction: column;
}

@media screen and (max-width: 1200px) {
    .spacer-img {
        width: 100%; 
        height: auto;
        margin: 40px 0;
        padding: 40px 0;
        background-position: 50% 50%;
        background-size: cover;
        @include flex-centre();
        flex-direction: column;

        h2, h4 {
            text-align: center;
        }
    }
}
footer {
  width: 100%;
  padding: 40px 0;
  background-color: black;

  .footer-inner-container {
    width: 1150px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    gap: 20px;
    margin: auto;

    .container-form-contianer {
      width: 100%;
      padding: 0;

      .form {
        width: 100%;
      }
    }

    .footer-logo {
      width: 250px;
    }

    .footer-info {
      font-size: 14pt;
      color: grey;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1200px) {
  footer {
    width: 100%;
    padding: 80px 0;
    background-color: black;

    .footer-inner-container {
      width: 90%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 20px;
      margin: auto;

      div {
        .container-form-contianer {
          padding: 0;
        }
      }

      .footer-logo {
        width: 300px;
      }

      .footer-info {
        font-size: 14pt;
        color: grey;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  footer {
    width: 100%;
    padding: 80px 0;
    background-color: black;

    .footer-inner-container {
      width: 90%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 20px;
      margin: auto;

      .footer-logo {
        width: 200px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  footer {
    width: 100%;
    padding: 80px 0;
    background-color: black;

    .footer-inner-container {
      width: 95%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 20px;
      margin: auto;

      .footer-logo {
        width: 100%;
      }
    }
  }
}

.nav {
  //width: 100vw;
  //background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  align-items: center;
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  //transform: translate(-50%);
  z-index: 2;
  transition: 0.4s ease all;

  .navInnerContainer {
    width: 1150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .logo-container {
      img {
        width: 200px;
      }
  
      .mobileLogo {
        display: none;
      }
    }
  }



  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;

    .mobileMenu {
      display: none;
    }

    .nav-link {
      color: rgb(255, 255, 255);
      font-size: 18pt;
      cursor: pointer;
      position: relative;
      transition: ease all 0.2s;

      .dropDownMenu {
        position: absolute;
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 200px;
        gap: 20px;
      }

      .nav-hover {
        transition: ease all 0.2s;
        &:hover {
          color: #b8df12;
        }
      }
    }
  }
}

.navScrolled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  align-items: center;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  //transform: translate(-50%);
  z-index: 2;
  transition: 0.4s ease all;

  .navInnerContainer {
    width: 1150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .logo-container {
      img {
        width: 150px;
      }
  
      .mobileLogo {
        display: none;
      }
    }
  }

  .nav-link-scrolled {
    color: white;
    font-size: 14pt;
    cursor: pointer;
    position: relative;
    transition: ease all 0.2s;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;

    .mobileMenu {
      display: none;
    }

    .nav-link {
      color: white;
      font-size: 18pt;
      cursor: pointer;
      position: relative;
      transition: ease all 0.2s;

      .dropDownMenu {
        position: absolute;
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 200px;
        gap: 20px;
      }

      .nav-hover {
        transition: ease all 0.2s;
        &:hover {
          color: #b8df12;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .nav,
  .navScrolled {
    width: 100%;
    padding: 20px 10px; 

    div {
      .mobileMenu {
        display: block;
      }
    }
    .nav-link, .nav-link-scrolled {
      display: none;
    }
  }
}

.Mobile-link {
  font-size: 20pt;
  border-bottom: 1px solid black;
  padding: 20px 0;
}

@use "../../App.scss" as *;

.page-header {
    width: 100vw;
    height: 240px !important;
    // background-image: url("../../assets/images/headerimg.jpg");
    // background-size: cover;
    // background-position: 50% 50%;
    background: rgb(77,165,255);
    background: linear-gradient(90deg, rgb(48, 132, 216) 20%, rgba(0,52,105,1) 80%);
    position: fixed;
    left:  0;
    height: 0;
    top: 0;
    transition: 0.4s ease all;


    div {
        width: 1150px;
        height: 100%;
        margin: auto;
        @include flex-centre();
        justify-content: flex-start;
        align-items: flex-end;
        padding: 10px 0;

    }
}

.page-header-scrolled {
    width: 100vw;
    height: 190px !important;
    // background-image: url("../../assets/images/headerimg.jpg");
    // background-size: cover;
    // background-position: 50% 50%;
    background: rgb(77,165,255);
    background: linear-gradient(90deg, rgb(48, 132, 216) 20%, rgba(0,52,105,1) 80%);
    position: fixed;
    left:  0;
    height: 0;
    top: 0;
    transition: 0.4s ease all;


    div {
        width: 1150px;
        height: 100%;
        margin: auto;
        @include flex-centre();
        justify-content: flex-start;
        align-items: flex-end;
        padding: 10px 0;

    }
}

@media screen and (max-width: 1200px) {
    .page-header {
        width: 100%;
        height: 250px;
        background-color: $blue;
    
        div {
            width: 90%;
            height: 100%;
            margin: auto;
            @include flex-centre();
            justify-content: flex-start;
            align-items: flex-end;
            padding: 10px 0;
    
        }
    }
}
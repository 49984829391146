@use "../../App.scss" as *;

.carouselContainer {
  position: relative;
  width: 100%;
  height: 1000px;

  .heroText {
    position: absolute;
    z-index: 1;
    width: 1150px;
    height: 100%;
    left: 50%;
    transform: translate(-75%);
    display: flex;
    align-items: center;

    .heroTextBkg {
      background-color: rgba(255, 255, 255, 0.5);
      padding: 20px;
    }
  }
}

.mobileHero {
  display: none;

  .mobileHeroText {
    width: 100%;
    padding: 20px;
    margin-top: 80px;
    background-color: $blue;
  }

  .mobileHeroImg {
    background-image: url("../../assets/images/Mobile-hero.jpg");
    width: 100%;
    height: 400px;
    background-size: cover;
  }
}

.heroCarousel {
  position: relative;
  width: 100%;
  @include flex-centre();

  .slide {
    width: 100%;
    height: 1000px;
    position: relative;
    @include flex-centre();

    img {
      width: 100%;
    }
  }
}

.hero-container {
  position: relative;
  width: 100%;
  height: 1000px;
  @include flex-centre();
  background-image: url("../../assets/images/new-hero-background.jpg");
  background-size: cover;
  background-position: 0% 50%;

  .hero-inner-container {
    width: 1150px;
    height: 100%;
    @include flex-centre();
    justify-content: space-between;

    // div {
    //   background-color: rgba(255, 255, 255, 0.5);
    //   padding: 15px;
    // }

    h2 {
      margin-top: 80px;
      text-shadow: 1px 0px 20px white;
    }

    .hero-logo {
      width: 300px;
    }
  }
}

@media screen and (max-width: 1750px) {
  .carouselContainer {
    position: relative;
    width: 100%;
    height: 800px;

    .heroText {
      position: absolute;
      z-index: 1;
      width: 1150px;
      height: 100%;
      left: 50%;
      transform: translate(-60%);
      display: flex;
      align-items: center;

      .heroTextBkg {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 20px;
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .carouselContainer {
    position: relative;
    width: 100%;
    height: 800px;

    .heroText {
      position: absolute;
      z-index: 1;
      width: 1150px;
      height: 100%;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      align-items: center;

      .heroTextBkg {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 20px;
      }
    }
  }

  .heroCarousel {
    position: relative;
    width: 100%;
    @include flex-centre();

    .slide {
      width: 100%;
      height: 800px;
      position: relative;
      @include flex-centre();

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .carouselContainer {
    position: relative;
    width: 100%;
    height: 600px;

    .heroText {
      position: absolute;
      z-index: 1;
      width: 1150px;
      height: 100%;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      align-items: center;

      .heroTextBkg {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 20px;
      }
    }
  }

  .heroCarousel {
    position: relative;
    width: 100%;
    @include flex-centre();

    .slide {
      width: 100%;
      height: 600px;
      position: relative;
      @include flex-centre();

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .carouselContainer {
    position: relative;
    width: 100%;
    height: 600px;
    display: none;

    .heroText {
      position: absolute;
      z-index: 1;
      width: 1150px;
      height: 100%;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      align-items: center;

      .heroTextBkg {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 20px;
      }
    }
  }

  .heroCarousel {
    position: relative;
    width: 100%;
    @include flex-centre();

    .slide {
      width: 100%;
      height: 600px;
      position: relative;
      @include flex-centre();

      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .mobileHero {
    display: block;

    .mobileHeroText {
      margin-top: 140px;
    }
  }
}

@media screen and (max-width: 900px) {
  .mobileHero {
    .slide {
      height: 500px;
    }
  }
}

@media screen and (max-width: 750px) {
  .mobileHero {
    .slide {
      height: 400px;
    }
  }
}

@media screen and (max-width: 500px) {
  .mobileHero {
    .slide {
      height: 300px;
    }
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "bilo", sans-serif;
  font-weight: 400;
  font-style: normal;
}

// .textOutline {
//   -webkit-text-stroke-width: 1px;
//   -webkit-text-stroke-color: white;
// }

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

a {
  color: inherit;
}

html {
  scroll-behavior: smooth;
}

.success {
  padding: 10px 20px;
  font-size: 14pt;
  background-color: #91be2f;
  color: white;
  margin-top: 10px;
  width: fit-content;
  border-radius: 10px;
}

.failed {
  padding: 10px 20px;
  font-size: 14pt;
  background-color: red;
  color: white;
  margin-top: 10px;
  width: fit-content;
  border-radius: 10px;
}

a {
  text-decoration: none;
  color: inherit;
}

//Colours
$green: #b8df12;
$yellow: #f8be00;
$blue: #003469;
$btnBlue: #1e81f7;

//Mixins
@mixin flex-centre {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spacer {
  background-color: $blue;
  height: 3px;
  width: 100%;
  margin: 20px 0;
}

.white {
  color: white;
}

.subhead {
  font-size: 36pt;
  margin-bottom: 20px;
  font-weight: 700;
}

.main-headline {
  font-size: 50pt;
  margin-bottom: 20px;
  line-height: 50pt;
}

.small-subhead {
  font-size: 16pt;
  margin-bottom: 20px;
  font-weight: 700;
}

.reg-weight {
  font-weight: 400;
}

.body-text {
  font-size: 15pt;
  margin-bottom: 20px;
  line-height: 20pt;
}

.green-text {
  color: $green;
  font-weight: bold;
}

.text-centre {
  text-align: center;
}

.green-divider {
  background-color: $green;
  width: 100%;
  height: 3px;
  margin: 20px 0;
}

.blue-divider {
  background-color: $blue;
  width: 100%;
  height: 3px;
  margin: 20px 0;
}

.blue-btn {
  color: white;
  background-color: $btnBlue;
  padding: 15px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-size: 13pt;

  &:hover {
    background-color: $blue;
  }
}

.large-img {
  max-width: 100%;
}

@media screen and (max-width: 1200px) {
  .main-headline {
    font-size: 40pt;
    line-height: 40pt;
    margin-bottom: 20px;
  }

  iframe {
    width: 100%;
    height: 600px;
    border: none;
  }

  .subhead {
    font-size: 30pt;
  }
}

@media screen and (max-width: 750px) {
  iframe {
    width: 100%;
    height: 500px;
    border: none;
  }
}

@media screen and (max-width: 400px) {
  .main-headline {
    font-size: 36pt;
    line-height: 36pt;
  }

  iframe {
    width: 100%;
    height: 400px;
    border: none;
  }
}

.page-component {
  width: 1150px;
  margin-top: 260px !important;
  padding: 80px 0;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 0.75fr;
  grid-template-rows: auto;
  gap: 40px;

  div {
    @include flex-centre();
    flex-direction: column;
    align-items: flex-start;

    .accessories-list li {
      font-size: 14pt;
      margin-bottom: 10px;
      //list-style-type: none;

      // &::before {
      //   content: "\2713";
      //   color: $green;
      //   margin-right: 10px;
      // }
    }

    .optional-list li {
      font-size: 14pt;
      margin-bottom: 10px;
    }
  }

  // #info-table {
  //   border-collapse: collapse;
  //   width: 100%;
  //   text-align: center;
  // }

  // #info-table td,
  // #info-table th {
  //   border: 1px solid #ddd;
  //   padding: 8px;
  //   text-align: center;
  // }

  // #info-table tr:nth-child(even) {
  //   background-color: #f2f2f2;
  // }

  // #info-table tr:hover {
  //   background-color: #ddd;
  // }

  // #info-table th {
  //   padding-top: 12px;
  //   padding-bottom: 12px;
  //   background-color: #91be2f;
  //   color: white;
  //   text-align: center;
  //   font-weight: bold;

  // }
}

@media screen and (max-width: 1200px) {
  .page-component {
    width: 90%;
    padding: 40px 0;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 40px;
  }
}

@media screen and (max-width: 700px) {
  .body-text {
    font-size: 12pt;
    margin-bottom: 20px;
    line-height: 18pt;
  }

  .main-headline {
    font-size: 30pt;
  }

  .subhead {
    font-size: 24pt;
    margin-bottom: 20px;
    font-weight: 700;
  }

  .page-component {
    width: 95%;
    padding: 40px 0;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 40px;

    div {
      width: 100%;
      padding: 10px;

      .large-img {
        width: 100%;
      }

      .accessories-list li,
      .optional-list li {
        font-size: 12pt;
      }
    }
  }
}

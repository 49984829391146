@use "../../App.scss" as *;

.values-container-outer {
  width: 1150px;
  margin: 50px auto;
  @include flex-centre();
  padding: 80px 0;
  gap: 40px;

  div {
    // width: 50%;
    flex-direction: column;

    .about-us-img {
      max-width: 100%;
      margin: auto;
    }
  }
}

.ourValuesSection {
  width: 1150px;
  margin: auto;

  .ourValuesGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;

    div {
      @include flex-centre();
      flex-direction: column;

      .ourValuesIcon {
        width: 100px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .values-container-outer {
    width: 90%;

    .about-us-container {
      width: 90%;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      padding: 40px 0;
      gap: 20px;

      div {
        width: 100%;
        @include flex-centre();
        flex-direction: column;
      }
    }

    .ourValuesSection {
      width: 90%;
      margin: auto;

      .ourValuesGrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 40px;

        div {
          @include flex-centre();
          flex-direction: column;

          .ourValuesIcon {
            width: 100px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .values-container-outer {
      width: 95%;

    .ourValuesSection {
      width: 95%;
      margin: auto;

      .ourValuesGrid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 40px;

        div {
          @include flex-centre();
          flex-direction: column;

          .ourValuesIcon {
            width: 100px;
          }
        }
      }
    }
  }
}

@use "../../App.scss" as *;

.whyChooseSection {
  width: 1150px;
  margin: auto;
  padding: 80px 0px;
  @include flex-centre();
  flex-direction: column;

  .whyChooseGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;

    div {
      @include flex-centre();
      flex-direction: column;
    }
  }

  .text-container {
    width: 75%;
  }
}

@media screen and (max-width: 1200px) {
  .whyChooseSection {
    width: 90%;
    margin: auto;
    @include flex-centre();
    flex-direction: column;

    .whyChooseGrid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 40px;

      div {
        @include flex-centre();
        flex-direction: column;
      }
    }
    .text-container {
      width: 100%;
    }
  }
}


@media screen and (max-width: 450px) {
    @media screen and (max-width: 1200px) {
        .whyChooseSection {
          width: 95%;
          margin: auto;
          @include flex-centre();
          flex-direction: column;
      
          .whyChooseGrid {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            gap: 20px;
      
            div {
              @include flex-centre();
              flex-direction: column;
            }
          }
          .text-container {
            width: 100%;
          }
        }
      }
  }